.c-filter-group {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $gray-400;
    padding-bottom: 15px;
    margin-top: 20px;
    &:first-of-type {
        margin-top: 0px;
    }

        .options-container {
            display: flex;
            flex-direction: column;
            margin-top: 5px;
            padding-left: 5px;
            gap: 5px;
            .ant-checkbox-wrapper + .ant-checkbox-wrapper {
                margin-left: 0px;
            }
        }
}