$primary: #106295;
$secondary: #106295;
$white: #ffffff;
$black: #000000;
$gray: #707070;
$dark-gray: #9F9F9F;


$wd-phone : 400px;
$wd-phone-wide : 480px;
$wd-phablet : 560px;
$wd-tablet-small : 640px;
$wd-tablet :  768px;
$wd-tablet-wide :  1024px;
$wd-desktop : 1248px;
$wd-desktop-wide: 1440px;

