.login-container{
    min-height: calc(100vh - 93px);
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .inner-container{
        width: 475px !important;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            @include FontAxi(36,2,$secondary);
            margin-bottom: 30px;
        }
        p {
            @include FontAxi(20,1,$white);
            margin-bottom: 55px;
        }

        a {
            @include FontAxi(17,1,$primary);
                
        }

        
        .ant-form-item{
            margin-bottom: 20px;
        }

        .c-button {
           margin-top: 20px;
           margin-bottom: 40px;
        }

        .ant-form-item,.c-button {
            width: 437px;
        }

        .more-pages{
            display: flex;
            flex-direction: column;
            gap: 15px;

            span {
                @include FontAxi(17,1,$white);
            }
        }

    }
}