.c-user-item{
        display: flex;
        flex-direction: column;
        border-radius: 10px;
        overflow: hidden;
        width: 250px;

        box-shadow: 0 2px 5px 1px rgba(64, 60, 67, 0.16);
        
        img {
            width: auto;
            height: 200px;
        }


        .text-container{

            display: flex;
            flex-direction: column;
            padding: 10px;

        h5 {
            margin-bottom: 3px;
            font-size: 15px;
            &:first-of-type{
                color: $primary;
                margin-bottom: 10px;
            }


        }

        span {
            margin-top: 15px;
                }

            }


}