.dashboard-container{
    min-height: calc(100vh - 93px);
    width: 100%;
    display: flex;

    .first-container {
        width: 250px;
        display: flex;
        padding: 20px 20px;
        flex-direction: column;
        border-right: 1px solid $gray-400;
        height: calc( 100vh - 57px);
        overflow: auto;
    }
    .second-container {
        flex: 1;
        display: flex;
        padding: 20px 20px;
        flex-direction: column;
        min-height: 100%;

        .c-filter-button{
            padding: 8px 15px;
            border: 1px solid $gray-400;
            border-radius: 15px;
            margin-left: 15px;
            cursor: pointer;
            font-weight: 700;
            font-size: 13px;
            &.active {
                background-color: $primary;
                color: $white
                ;
            }
        }

        .users-container {
            display: flex;
            gap:25px;
            flex-wrap: wrap;
            height: calc(100vh - 324px);
    overflow: auto;
        }

    }
  
}