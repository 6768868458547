.c-header {
    display: flex;
    flex-direction: column;


    .first-header{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding:0px 40px 0px 40px;
        background-color: $white;
        height: 56px;
        border-bottom: 1px solid $gray-400;
        
        
        .logo{
            width: 150px;
            margin-top: 10px;
        }

        .actions-bar{
            display: flex;
            align-items: center;
            gap: 20px;

        }
        
    }

}