// all sass files are combined here 

@import "~bootstrap/scss/bootstrap";
@import '~antd/dist/antd.css';
@import "variables";
@import "mixins";
@import "fonts";
@import "./global";

@import '../../components/index.scss';
@import '../../pages/index.scss';


