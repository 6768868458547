
    @font-face {
        font-family: 'Acumin Pro Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Acumin Pro'), url('../fonts/Acumin-RPro.woff') format('woff');
    }
    @font-face {
        font-family: 'Acumin Pro Light';
        font-style: normal;
        font-weight: normal;
        src: local('acumin-light.woff2'), url('../fonts/acumin-light.woff2.ttf') format('woff');
    }
        
    
    @font-face {
        font-family: 'Acumin Pro Italic';
        font-style: normal;
        font-weight: normal;
        src: local('Acumin Pro Italic'), url('../fonts/Acumin-ItPro.woff') format('woff');
    }
    

    @font-face {
        font-family: 'Acumin Pro Bold';
        font-style: normal;
        font-weight: normal;
        src: local('Acumin Pro Bold'), url('../fonts/Acumin-BdPro.woff') format('woff');
    }
    

    @font-face {
        font-family: 'Acumin Pro Bold Italic';
        font-style: normal;
        font-weight: normal;
        src: local('Acumin Pro Bold Italic'), url('../fonts/Acumin-BdItPro.woff') format('woff');
    }
    @font-face {
        font-family: 'Acumin Pro Semibold Regular';
        font-style: normal;
        font-weight: normal;
        src: local('Acumin Pro Semibold Regular'), url('../fonts/Acumin Pro Semibold Regular.ttf') format('woff');
    }