
.c-footer{
    height: 36px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
     
    span {
        @include FontAxi(12,1,$white);
    }
}